/* TopLoadingBar.css */

.top-loading-bar-container {
    @apply w-full h-1 bg-transparent;
}

.top-loading-bar {
    @apply w-full h-full bg-gradient-to-r from-primary to-accent;
    background-size: 200% 100%;
    animation: loading 2s linear infinite;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}